<script setup lang="ts">
  import storage from '@/utils/storage';
  import { isWeixinBrowser } from '@/utils';
  import { getOpenId } from '@/api';
  import { useUserStore } from '@/store';

  const { proxy } = getCurrentInstance();
  const { $isResolve } = proxy;

  function toAuth(path, query) {
    const app_id = import.meta.env.VITE_WEIXIN_APP_ID;
    const apiUrl = import.meta.env.VITE_GLOB_API_URL;

    // 判断是否开发环境，开发环境这里走内网穿透方便授权调试
    if (import.meta.env.MODE === 'development') {
      // 将 query 转为字符串
      const queryString = Object.keys(query)
        .map((key) => `${key}=${query[key]}`)
        .join('&');

      const redirect_uri = encodeURIComponent(`http://payment.frp.nxun.cn:10021/${path}?${queryString}`);
      window.location.href = `https://service.api.jxhhyun.com/wechat/oauth?appid=${app_id}&scope=snsapi_base&redirect_uri=${redirect_uri}`;
    } else {
      const redirect_uri = encodeURIComponent(window.location.href);
      window.location.href = `${apiUrl}/wechat/oauth?appid=${app_id}&scope=snsapi_base&redirect_uri=${redirect_uri}`;
      // window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${app_id}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`;
    }
  }

  async function toGetOpenId(code: string) {
    const { openid } = await getOpenId(code);

    // 缓存 openId
    const userStore = useUserStore();
    userStore.setUserInfo({ openId: openid });
    $isResolve();
  }

  onLaunch(({ path, query }) => {
    console.log('App Launch');

    // 获取进入时的org参数信息
    storage.set('open_query', query);

    const userStore = useUserStore();
    if (isWeixinBrowser()) {
      if (!userStore.isLogined && !query?.code) {
        toAuth(path, query);
      }

      if (!userStore.isLogined && query?.code) {
        toGetOpenId(query.code);
      } else {
        $isResolve();
      }
    }
    // else if (isAlipayBrowser()) {
    //   uni.showModal({
    //     title: '提示',
    //     content: '在支付宝浏览器中',
    //     showCancel: false,
    //   });
    // }
    else {
      uni.reLaunch({ url: '/pages/error/index?clear=1' });
    }
  });

  onShow(() => {
    console.log('App Show');
  });

  onHide(() => {
    console.log('App Hide');
  });
</script>

<style lang="scss">
  @import '@tuniao/tn-style/dist/uniapp/index.css';
</style>
