import { defineStore } from 'pinia';
import { ref } from 'vue';

const initState = { openId: '', org_name: '' };

export const useUserStore = defineStore(
  'user',
  () => {
    const userInfo = ref<Recordable>({ ...initState });

    const setUserInfo = (val: Recordable) => {
      userInfo.value = val;
    };

    const getUserInfo = (key: string | undefined) => {
      return key ? userInfo.value[key] : userInfo.value;
    };

    const clearUserInfo = () => {
      userInfo.value = { ...initState };
    };
    // 一般没有reset需求，不需要的可以删除
    const reset = () => {
      userInfo.value = { ...initState };
    };

    const isLogined = computed(() => !!getUserInfo('openId'));

    return {
      userInfo,
      setUserInfo,
      getUserInfo,
      clearUserInfo,
      isLogined,
      reset,
    };
  },
  {
    persist: true,
  },
);
